#footer {
  background-color: #fff;
  box-shadow: 0 0 0.4rem 0.1rem rgb(181, 183, 190);
  padding: 40px;
  text-align: center;
}

#footer a {
  text-decoration: none;
}
