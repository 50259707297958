@media (max-width: 1199px) {
  #navigation {
    position: fixed !important;
    bottom: 0;
  }
  #navigation ul {
    display: flex;
    justify-content: space-around;
  }
  body {
    padding-bottom: 62px !important;
  }
}

#navigation {
  position: relative;
  z-index: 10;
  box-shadow: 0 -1px 1px 0px #999;
  width: 100%;
  background-color: #fff;
  backface-visibility: hidden;
}
#navigation ul {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

#navigation ul li {
  display: inline-block;
  vertical-align: middle;
}
#navigation ul li a {
  display: block;
  padding: 20px;
  text-decoration: none;
  color: #333;
  transition: background 0.2s;
}
#navigation ul li a:hover,
#navigation ul li a.active {
  background-color: #f0f0f0;
  transition: background 0.2s;
}

#navigation ul li.logo a {
  padding: 15px 20px;
}
#navigation ul li a img {
  max-height: 27px;
}
