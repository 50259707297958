header {
  display: flex;
  justify-content: space-between;
}

#vCard {
  flex: 2;
}
#vCard img {
  width: 100px;
  float: left;
  display: block;
  border: 5px solid #363a3f;
  margin: 0 20px 20px 0;
}
#vCard p {
  margin-bottom: 0;
}

#vita {
  flex: 3;
}

@media (max-width: 1000px) {
  header {
    display: block;
  }
  #vCard,
  #vita {
    width: 100%;
    margin: 20px 0;
  }
}

div.card > h2 {
  padding: 0;
  margin-bottom: 0;
}
div.card h3 {
  margin: 0.8rem 0 0;
}
div.card h4 {
  margin: 0;
  font-size: 0.8em;
}

.card {
  transition: box-shadow 0.3s;
}
.card:hover {
  box-shadow: 0 0 0.8rem 0.1rem rgb(167, 169, 175);
  transition: box-shadow 0.3s;
}

.experienceItem {
  margin-bottom: 2rem;
}
.experienceItem ul {
  margin: 0;
}
.experienceItem ul li {
  line-height: 2rem;
}
