#projects {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

#projects > div {
  width: calc(33% - 10px);
  background-color: #fff;
  margin-bottom: 20px;
  margin-right: 20px;
  box-shadow: 0 0 0.4rem 0.1rem rgb(181, 183, 190);
  position: relative;
  padding-bottom: 52px;
  transition: box-shadow 0.3s;
}
@media (min-width: 1001px) {
  #projects > div:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 1000px) {
  #projects > div {
    width: calc(50% - 10px);
  }
  #projects > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media (max-width: 600px) {
  #projects > div {
    width: 100%;
    margin: 0 0 4rem;
  }
}

#projects > div .img {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
}
#projects > div:hover .img {
  /*background-size: inherit;*/
  animation: background-pan linear 4s infinite;
}
#projects > div:hover {
  transition: box-shadow 0.3s;
  box-shadow: 0 0 0.8rem 0.1rem rgb(167, 169, 175);
}

#projects > div h2,
#projects > div p {
  margin: 10px 20px 10px;
}
#projects > div a {
  text-decoration: none;
}
#projects > div a.visitlink {
  display: inline-block;
  padding: 1rem 2rem;
  text-align: right;
  position: absolute;
  bottom: 0;
}
#projects > div a:hover {
  background-color: #f0f0f0;
}

@keyframes background-pan {
  0% {
    background-position: top left;
  }
  50% {
    background-position: bottom right;
  }
  100% {
    background-position: top left;
  }
}
