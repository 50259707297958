#pagetitle {
  background-color: #363a3f;
  padding: 20px 0 20px;
  overflow: hidden;
  box-shadow: 0 0 0.4rem 0.1rem rgb(181, 183, 190);
}
#pagetitle h1 {
  max-width: 1280px;
  margin: 0 auto;
  font-weight: normal;
  font-size: 1.6em;
  color: #dadddd;
  animation: fade-from-right ease-out 0.8s;
}
@keyframes fade-from-right {
  0% {
    transform: translateX(5%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
