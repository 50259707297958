@import url("https://rsms.me/inter/inter.css");
html {
  font-family: "Inter", sans-serif;
  line-height: 1.7rem;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

document,
body {
  margin: 0;
  padding: 0;
}
body {
  background-color: #bdc2cc;
}
.wrapper {
  max-width: 1280px;
  min-height: calc(100vh - 62px);
  margin: 0 auto 20px;
}

.card {
  background-color: #fff;
  padding: 2rem;
  box-sizing: border-box;
  box-shadow: 0 0 0.4rem 0.1rem rgb(181, 183, 190);
  margin-top: 20px;
}
.card + .card {
  margin-left: 20px;
}
.card h2 {
  margin-top: 0;
  font-size: 1.2em;
}

a {
  color: dodgerblue;
}

.card a {
  text-decoration: none;
}
