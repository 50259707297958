@import url(https://rsms.me/inter/inter.css);
body {
  margin: 0;
  padding: 0;
}

html {
  font-family: "Inter", sans-serif;
  line-height: 1.7rem;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

document,
body {
  margin: 0;
  padding: 0;
}
body {
  background-color: #bdc2cc;
}
.wrapper {
  max-width: 1280px;
  min-height: calc(100vh - 62px);
  margin: 0 auto 20px;
}

.card {
  background-color: #fff;
  padding: 2rem;
  box-sizing: border-box;
  box-shadow: 0 0 0.4rem 0.1rem rgb(181, 183, 190);
  margin-top: 20px;
}
.card + .card {
  margin-left: 20px;
}
.card h2 {
  margin-top: 0;
  font-size: 1.2em;
}

a {
  color: dodgerblue;
}

.card a {
  text-decoration: none;
}

@media (max-width: 1199px) {
  #navigation {
    position: fixed !important;
    bottom: 0;
  }
  #navigation ul {
    display: flex;
    justify-content: space-around;
  }
  body {
    padding-bottom: 62px !important;
  }
}

#navigation {
  position: relative;
  z-index: 10;
  box-shadow: 0 -1px 1px 0px #999;
  width: 100%;
  background-color: #fff;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
#navigation ul {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

#navigation ul li {
  display: inline-block;
  vertical-align: middle;
}
#navigation ul li a {
  display: block;
  padding: 20px;
  text-decoration: none;
  color: #333;
  transition: background 0.2s;
}
#navigation ul li a:hover,
#navigation ul li a.active {
  background-color: #f0f0f0;
  transition: background 0.2s;
}

#navigation ul li.logo a {
  padding: 15px 20px;
}
#navigation ul li a img {
  max-height: 27px;
}

#footer {
  background-color: #fff;
  box-shadow: 0 0 0.4rem 0.1rem rgb(181, 183, 190);
  padding: 40px;
  text-align: center;
}

#footer a {
  text-decoration: none;
}

#terminal {
	background-color: #1a1a1a;
	color: #dadada;
	font-family: 'Consolas', monospace;
	height: calc(100vh - 62px);
	font-size: 2.5vh;
	line-height: 3.5vh;
	padding: 20px;
	box-sizing: border-box;
	position: relative;
	z-index: 1;
}
#terminal .meta {
	color: #666;
}
#terminal .caret:before {
	content: '> ';
	color: #666;
}
#terminal a {
	color: dodgerblue;
}
header {
  display: flex;
  justify-content: space-between;
}

#vCard {
  flex: 2 1;
}
#vCard img {
  width: 100px;
  float: left;
  display: block;
  border: 5px solid #363a3f;
  margin: 0 20px 20px 0;
}
#vCard p {
  margin-bottom: 0;
}

#vita {
  flex: 3 1;
}

@media (max-width: 1000px) {
  header {
    display: block;
  }
  #vCard,
  #vita {
    width: 100%;
    margin: 20px 0;
  }
}

div.card > h2 {
  padding: 0;
  margin-bottom: 0;
}
div.card h3 {
  margin: 0.8rem 0 0;
}
div.card h4 {
  margin: 0;
  font-size: 0.8em;
}

.card {
  transition: box-shadow 0.3s;
}
.card:hover {
  box-shadow: 0 0 0.8rem 0.1rem rgb(167, 169, 175);
  transition: box-shadow 0.3s;
}

.experienceItem {
  margin-bottom: 2rem;
}
.experienceItem ul {
  margin: 0;
}
.experienceItem ul li {
  line-height: 2rem;
}

#pagetitle {
  background-color: #363a3f;
  padding: 20px 0 20px;
  overflow: hidden;
  box-shadow: 0 0 0.4rem 0.1rem rgb(181, 183, 190);
}
#pagetitle h1 {
  max-width: 1280px;
  margin: 0 auto;
  font-weight: normal;
  font-size: 1.6em;
  color: #dadddd;
  -webkit-animation: fade-from-right ease-out 0.8s;
          animation: fade-from-right ease-out 0.8s;
}
@-webkit-keyframes fade-from-right {
  0% {
    transform: translateX(5%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-from-right {
  0% {
    transform: translateX(5%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

#projects {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

#projects > div {
  width: calc(33% - 10px);
  background-color: #fff;
  margin-bottom: 20px;
  margin-right: 20px;
  box-shadow: 0 0 0.4rem 0.1rem rgb(181, 183, 190);
  position: relative;
  padding-bottom: 52px;
  transition: box-shadow 0.3s;
}
@media (min-width: 1001px) {
  #projects > div:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 1000px) {
  #projects > div {
    width: calc(50% - 10px);
  }
  #projects > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media (max-width: 600px) {
  #projects > div {
    width: 100%;
    margin: 0 0 4rem;
  }
}

#projects > div .img {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
}
#projects > div:hover .img {
  /*background-size: inherit;*/
  -webkit-animation: background-pan linear 4s infinite;
          animation: background-pan linear 4s infinite;
}
#projects > div:hover {
  transition: box-shadow 0.3s;
  box-shadow: 0 0 0.8rem 0.1rem rgb(167, 169, 175);
}

#projects > div h2,
#projects > div p {
  margin: 10px 20px 10px;
}
#projects > div a {
  text-decoration: none;
}
#projects > div a.visitlink {
  display: inline-block;
  padding: 1rem 2rem;
  text-align: right;
  position: absolute;
  bottom: 0;
}
#projects > div a:hover {
  background-color: #f0f0f0;
}

@-webkit-keyframes background-pan {
  0% {
    background-position: top left;
  }
  50% {
    background-position: bottom right;
  }
  100% {
    background-position: top left;
  }
}

@keyframes background-pan {
  0% {
    background-position: top left;
  }
  50% {
    background-position: bottom right;
  }
  100% {
    background-position: top left;
  }
}

#address:before {
	content: '\48\6f\6d\61\72\73\74\72\2e\0a\32\34';
}

#address2:before {
	content: '\35\31\31\30\37\0a\4b\F6\6c\6e';
}

#email:before {
content: '\79\61\73\61\2e\79\65\6e\65\72\40\6c\69\76\65\2e\64\65';
}
