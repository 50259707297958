#terminal {
	background-color: #1a1a1a;
	color: #dadada;
	font-family: 'Consolas', monospace;
	height: calc(100vh - 62px);
	font-size: 2.5vh;
	line-height: 3.5vh;
	padding: 20px;
	box-sizing: border-box;
	position: relative;
	z-index: 1;
}
#terminal .meta {
	color: #666;
}
#terminal .caret:before {
	content: '> ';
	color: #666;
}
#terminal a {
	color: dodgerblue;
}